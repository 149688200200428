import { Suspense, lazy, useState } from "react";
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Lazy load pages
const Index = lazy(() => import("./pages/Index"));
const About = lazy(() => import("./pages/About"));
const GeneralDentistry = lazy(() => import("./pages/services/GeneralDentistry"));
const CosmeticDentistry = lazy(() => import("./pages/services/CosmeticDentistry"));
const Orthodontics = lazy(() => import("./pages/services/Orthodontics"));
const DentalImplants = lazy(() => import("./pages/services/DentalImplants"));
const PediatricDentistry = lazy(() => import("./pages/services/PediatricDentistry"));
const EmergencyCare = lazy(() => import("./pages/services/EmergencyCare"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const BookAppointment = lazy(() => import("./pages/BookAppointment"));

// Loading component
const PageLoader = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
  </div>
);

const App = () => {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
        retry: 1,
        refetchOnWindowFocus: false,
      },
    },
  }));

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <Toaster />
        <Sonner />
        <BrowserRouter>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/about" element={<About />} />
              <Route path="/services/general-dentistry" element={<GeneralDentistry />} />
              <Route path="/services/cosmetic-dentistry" element={<CosmeticDentistry />} />
              <Route path="/services/orthodontics" element={<Orthodontics />} />
              <Route path="/services/dental-implants" element={<DentalImplants />} />
              <Route path="/services/pediatric-dentistry" element={<PediatricDentistry />} />
              <Route path="/services/emergency-care" element={<EmergencyCare />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/book-appointment" element={<BookAppointment />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </TooltipProvider>
    </QueryClientProvider>
  );
};

export default App;